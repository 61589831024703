// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HouseItem {
  background-color: #DAAD86;
  border-radius: 10px;
  width: 300px;
  margin: 20px;
}
.HouseItem img {
  width: 100%;
}
.HouseItem .HouseConteinerDown {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.HousesPage {
  width: 80%;
  margin: auto;
}
.HousesPage .serch {
  margin-top: 50px;
  width: 80%;
}
.HousesPage .HouseItems {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/Houses/Houses.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AAER;;AAGA;EAEI,UAAA;EACA,YAAA;AADJ;AAEI;EACI,gBAAA;EACA,UAAA;AAAR;AAGI;EACI,aAAA;AADR","sourcesContent":[".HouseItem {\n    background-color: #DAAD86;\n    border-radius: 10px;\n    width: 300px;\n    margin: 20px;\n    & img {\n        width: 100%;\n    }\n    & .HouseConteinerDown {\n        display: flex;\n        justify-content: space-between;\n        padding: 20px;\n    }\n}\n\n\n.HousesPage {\n    // background-color: red;\n    width: 80%;\n    margin: auto;\n    & .serch {\n        margin-top: 50px;\n        width: 80%;\n        // background-color: #DAAD86;\n    }\n    & .HouseItems {\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
