import './Button.scss'

interface IButton {
    text?: string;
    onClick?:  React.MouseEventHandler<HTMLButtonElement>;
    color?: "blue" | "green" | "red";
    size?: "small" | "medium" | "large"; 
    fullWidth?: boolean;

}

export const Button = ({ text, onClick, color = "blue", size = "medium", fullWidth=false }: IButton) => {
    return <>
        <button className={`customButton ${color} ${size} ${fullWidth ? 'fullWidth' : ''}`} onClick={onClick} >
            {text}
        </button>
    </>
}