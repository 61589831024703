import React from 'react';
import './Input.scss';
// import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";

interface IInput {
  type?: 'text' | 'password' | 'email' | 'number';
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  isPhone?: boolean;
}

export const Input = ({
  type = 'text',
  placeholder = '',
  value,
  onChange,
  size = 'medium',
  fullWidth = false,
  isPhone=false
}:IInput) => {

  if (!isPhone){

    return (
      <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`customInput ${size} ${fullWidth ? 'fullWidth' : ''}`}
      />
    );
  }

  return (
    <PhoneInput
      country={"kz"}
      specialLabel=""
      placeholder='+7 (___) ___-__-__'
      value={value}
      onChange={(phone) => onChange?.({ target: { value: phone } } as React.ChangeEvent<HTMLInputElement>)}
      inputClass={`customInput ${size} ${fullWidth ? 'fullWidth' : ''}`}
    />
  );
};
