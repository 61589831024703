import { Header } from './components/header/Header';
import { Routes, Route } from 'react-router-dom';
import './styles/main.scss'
import { Footer } from './components/footer/footer';
import { Landing } from './pages/landing/Landing';
import { Houses } from './pages/Houses/Houses';

function App() {
  return (
    <>
      <Header/>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='House' element={<Houses/>}/>
        <Route path=''/>

      </Routes>
      <Footer/>
    </>
  );
}

export default App;
