// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #4B470C;
  color: white;
}
footer .footerConteiner {
  width: 1350px;
  margin: auto;
}
footer .footerConteiner hr {
  color: white;
}
footer .footerConteiner .footerDown {
  padding: 20px;
  text-align: center;
  font-size: 15px;
}
footer .footerConteiner .footerUp {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
footer .footerConteiner .footerUp .list ul {
  list-style: none;
}
footer .footerConteiner .footerUp .list li {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;AACJ;AAAI;EACI,aAAA;EACA,YAAA;AAER;AADQ;EACI,YAAA;AAGZ;AADQ;EACI,aAAA;EACA,kBAAA;EACA,eAAA;AAGZ;AADQ;EACI,aAAA;EACA,6BAAA;EACA,aAAA;AAGZ;AADgB;EACI,gBAAA;AAGpB;AADgB;EACI,iBAAA;AAGpB","sourcesContent":["footer {\n    background-color: #4B470C;\n    color: white;\n    & .footerConteiner {\n        width: 1350px;\n        margin: auto;\n        & hr {\n            color: white;\n        }\n        & .footerDown {\n            padding: 20px;\n            text-align: center;   \n            font-size: 15px;         \n        }\n        & .footerUp {\n            display: flex;\n            justify-content: space-around;\n            padding: 20px;\n            & .list {\n                & ul {\n                    list-style: none;\n                }\n                & li {\n                    padding-top: 10px;\n                }\n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
