import './Header.scss'


export const Header = () => {
    return (
        <header className='header'>
            <h1>Shanyraq</h1>
            <ul>
                <li>о нас</li>
                <li>Продажа</li>
                <li>Избанные</li>
            </ul>

            <button>hello</button>

        </header>
    )
}