import './footer.scss'

export const Footer = () => {
    return (
        <footer>
            <div className='footerConteiner' >
                <div className='footerUp'>

                    <h1>Sanyraq</h1>

                    <div className='list'> 
                        <h3>о нас</h3> 
                        <ul>
                            <li>Компания</li>
                        </ul>
                    </div>
                    <div className='list'> 
                        <h3>подержка</h3> 
                        <ul>
                            <li>Центр помощи</li>
                            <li>Политика конфиденциальности</li>
                            <li>Правила пользования</li>
                            <li>FAQ</li>
                        </ul>
                    </div>
                    <div className='list'> 
                        <h3>сотсети</h3>
                        <ul>
                            <li>Facebook</li>
                            <li>Instagram</li>
                            <li>Twitter</li>
                            
                        </ul>
                    </div>
                </div>

                <hr/>
                <div className="footerDown">© 2025 Shanyraq.com.kz — Все права защищены.</div>
            </div>



        </footer>
    )
}