// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customButton {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  margin-bottom: 10px;
}
.customButton.small {
  font-size: 12px;
  padding: 5px 10px;
}
.customButton.medium {
  font-size: 16px;
  padding: 10px 20px;
}
.customButton.large {
  font-size: 20px;
  padding: 15px 30px;
}
.customButton.blue {
  background: #659DBD;
  color: white;
}
.customButton.blue:hover {
  background: rgb(33.1380753138, 125.1882845188, 186.8619246862);
}
.customButton.green {
  background: #2ecc71;
  color: white;
}
.customButton.green:hover {
  background: rgb(36.616, 162.384, 89.948);
}
.customButton.red {
  background: #e74c3c;
  color: white;
}
.customButton.red:hover {
  background: rgb(213.698630137, 43.8356164384, 26.301369863);
}
.customButton.fullWidth {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/UI/Button/Button.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,gCAAA;EACA,kBAAA;EACA,mBAAA;AADF;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,eAAA;EACA,kBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,YAAA;AAHJ;AAKI;EACE,8DAAA;AAHN;AAOE;EACE,mBAAA;EACA,YAAA;AALJ;AAOI;EACE,wCAAA;AALN;AASE;EACE,mBAAA;EACA,YAAA;AAPJ;AASI;EACE,2DAAA;AAPN;AAWE;EACE,WAAA;AATJ","sourcesContent":["@use \"sass:color\";\n\n.customButton {\n  border: none;\n  cursor: pointer;\n  padding: 10px 20px;\n  font-weight: bold;\n  transition: all 0.3s ease-in-out;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  &.small {\n    font-size: 12px;\n    padding: 5px 10px;\n  }\n\n  &.medium {\n    font-size: 16px;\n    padding: 10px 20px;\n  }\n\n  &.large {\n    font-size: 20px;\n    padding: 15px 30px;\n  }\n\n  &.blue {\n    background: #659DBD;\n    color: white;\n\n    &:hover {\n      background: color.adjust(#3498db, $lightness: -10%);\n    }\n  }\n\n  &.green {\n    background: #2ecc71;\n    color: white;\n\n    &:hover {\n      background: color.adjust(#2ecc71, $lightness: -10%);\n    }\n  }\n\n  &.red {\n    background: #e74c3c;\n    color: white;\n\n    &:hover {\n      background: color.adjust(#e74c3c, $lightness: -10%);\n    }\n  }\n\n  &.fullWidth {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
