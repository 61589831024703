// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header.header {
  color: white;
  display: flex;
  justify-content: space-around;
  background-color: #4B470C;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
header.header ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}
header.header ul li {
  font-size: 20px;
  padding: 10px;
}
header.header button {
  all: unset;
  background-color: white;
  width: 70px;
  border-radius: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,6BAAA;EACA,yBAAA;EACA,aAAA;EACA,+BAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;AACJ;AAAI;EAEI,aAAA;EACA,6BAAA;EACA,gBAAA;AACR;AAAQ;EACI,eAAA;EACA,aAAA;AAEZ;AACI;EACI,UAAA;EACA,uBAAA;EACA,WAAA;EACA,mBAAA;AACR","sourcesContent":["header.header {\n    color: white;\n    display: flex;\n    justify-content: space-around;\n    background-color: #4B470C;\n    padding: 20px;\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n    position: fixed;\n    width: 100%;\n    z-index: 1000;\n    & ul {\n        \n        display: flex;\n        justify-content: space-around;\n        list-style: none;\n        & li {\n            font-size: 20px;\n            padding: 10px;\n        }\n    }\n    & button {\n        all: unset;\n        background-color: white;\n        width: 70px;\n        border-radius: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
