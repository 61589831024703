// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border 0.3s ease-in-out;
  outline: none;
  margin: 20px;
}
.customInput.small {
  font-size: 12px;
  padding: 5px;
}
.customInput.medium {
  font-size: 16px;
  padding: 10px;
}
.customInput.large {
  font-size: 20px;
  padding: 15px;
}
.customInput.fullWidth {
  width: 90%;
}
.customInput:focus {
  border-color: #3498db;
}`, "",{"version":3,"sources":["webpack://./src/UI/Input/Input.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mCAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAEI;EACE,eAAA;EACA,YAAA;AAAN;AAGI;EACE,eAAA;EACA,aAAA;AADN;AAII;EACE,eAAA;EACA,aAAA;AAFN;AAKI;EACE,UAAA;AAHN;AAMI;EACE,qBAAA;AAJN","sourcesContent":[".customInput {\n  \n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    transition: border 0.3s ease-in-out;\n    outline: none;\n    margin: 20px;\n    \n    &.small {\n      font-size: 12px;\n      padding: 5px;\n    }\n  \n    &.medium {\n      font-size: 16px;\n      padding: 10px;\n    }\n  \n    &.large {\n      font-size: 20px;\n      padding: 15px;\n    }\n  \n    &.fullWidth {\n      width: 90%;\n    }\n  \n    &:focus {\n      border-color: #3498db;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
