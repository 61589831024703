// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
main.landing {
  width: 100%;
}
main.landing img {
  width: 100%;
  object-fit: cover;
  height: 1000px;
}
main.landing .landingContainer {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center; /* Если нужен центрированный текст */
}
main.landing .landingContainer h1 {
  font-size: 100px;
  padding-bottom: 20px;
}
main.landing .landingContainer p {
  padding-bottom: 20px;
  font-size: 30px;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/pages/landing/Landing.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;AAEJ;AADI;EACI,WAAA;EACA,iBAAA;EACA,cAAA;AAGR;AADI;EACI,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,kBAAA,EAAA,oCAAA;AAGR;AAFQ;EACI,gBAAA;EACA,oBAAA;AAIZ;AAFQ;EACI,oBAAA;EACA,eAAA;EACA,YAAA;AAIZ","sourcesContent":["main.landing {\n    width: 100%;\n    & img {\n        width: 100%;\n        object-fit: cover;\n        height: 1000px;\n    }\n    & .landingContainer {\n        color: white;\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 100%;\n        text-align: center; /* Если нужен центрированный текст */\n        & h1 {\n            font-size: 100px;\n            padding-bottom: 20px;\n        }\n        & p {\n            padding-bottom: 20px;\n            font-size: 30px;\n            color: black;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
