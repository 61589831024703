import { Input } from "../../UI/Input/Input"
import { HouseItem } from "./HouseItem"
import './Houses.scss'

export const Houses = () => {
    return (
        <main className="HousesPage">

            <div className="serch" >
                <Input fullWidth/>
            </div>

            <div className="HouseItems">

                <HouseItem/>
                <HouseItem/>
                <HouseItem/>
                <HouseItem/>

            </div>
            <div className="HouseItems">

                <HouseItem/>
                <HouseItem/>
                <HouseItem/>
                <HouseItem/>

            </div>            
            <div className="HouseItems">

                <HouseItem/>
                <HouseItem/>
                <HouseItem/>
                <HouseItem/>

            </div>            
            <div className="HouseItems">

                <HouseItem/>
                <HouseItem/>
                <HouseItem/>
                <HouseItem/>

            </div>

        </main>
    )
}