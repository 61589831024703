// import landingImage from '/landing-page.jpeg'
import { Button } from '../../UI/Button/Button'
import './Landing.scss'
export const Landing = () => {
    return (
        <main className='landing' >
            <img src='/landing-page.jpeg' alt="тут фото" />
            <div className='landingContainer' >

                <h1>Shanyraq</h1>
                <p>Жоғары рейтингтер мен тиімді бағалар</p>
                <Button text='Тіркелу' size='large'/>
            </div>
        </main>
    )
}