import { useEffect } from 'react'
import './Houses.scss'
import axios from 'axios'


export const HouseItem = () => {
    useEffect(() => {
        (async () => {
            const { data } = await axios.get('https://sanyraq.com.kz/api')
            console.log(data)
        })()
    },[])
    return (
        <div className="HouseItem"  >
            <img src="https://kz.ruplans.ru/cms_files/98/184/3794/vid1.jpg" alt=""/>
            <div className='HouseConteinerDown'>
                <div>
                    <h2>title</h2>
                    <h3>400000</h3>
                </div>
                <div>
                    <h3>4.5</h3>
                </div>
            </div>
        </div>
    )
}